<template>
  <v-container fluid>
    <v-expansion-panels dense small focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-container">
            <v-icon color="primary">mdi-filter</v-icon>
            <span>Filters</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="my-3">
          <v-row dense>
            <v-col cols="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="options.startDate"
                    label="Start Date"
                    readonly
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.startDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="options.endDate"
                    label="End Date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.endDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-radio-group row dense v-model="options.DocStatus">
                <v-radio label="Open" value="O"></v-radio>
                <v-radio label="Closed" value="C"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                label="Search by separating items using a comma(,)"
                dense
                v-model="options.search"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="options.drivers"
                :items="drivers"
                dense
                attach
                chips
                small-chips
                clearable
                deletable-chips
                label="Select Driver"
                item-text="RlpName"
                item-value="RlpCode"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-btn small color="primary" @click="getOpenItems"
                >Apply Filters <v-icon>mdi-card-search</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          v-model="selectedItems"
          dense
          small
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="OpenItems"
          :search="search"
          show-select
          :options.sync="options"
          :server-search="true"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon color="green" @click="editItem(item)">mdi-eye</v-icon>
          </template>

          <template v-slot:item.undo="{ item }">
            <v-icon small color="red" @click="reverseDoc(item)"
              >mdi-undo</v-icon
            >
          </template>
          <template v-slot:item.id="props">
            <v-btn
              color="primary"
              text
              :to="`/dispatch/document/${props.item.id}/${props.item.ObjType}`"
              >{{ props.item.id }}</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <show-dialog
      :editedItem="editedItem"
      :dialog="dialog"
      @close="dialog = false"
    ></show-dialog>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import ShowDialog from "../_components/show-dialog.vue";

export default {
  components: {
    ShowDialog,
  },
  data() {
    return {
      drivers: [],
      loader: false,
      search: null,
      editedItem: {},
      selectedItems: [],
      Driver: null,
      vehicles: [],
      Vehicle: null,
      dialog: false,
      selectedData: null,
      groupByEnabled: false,
      DocStatus: "O",
      updateRange: null,
      options: {
        startDate: null,
        endDate: null,
        DocStatus: "O",
        search: null,
        drivers: [],
      },
      headers: [
        { text: "#", value: "id" },
        { text: "Doc No.", value: "DocNum" },
        { text: "Bp Code", value: "CardCode" },
        { text: "BP Name", value: "CardName" },
        { text: "Date", value: "DocDate" },
        { text: "Sales Employee", value: "oslp.SlpName" },
        { text: "Driver", value: "driver.RlpName" },
        { text: "Reverse", value: "undo" },
        { text: "Items", value: "actions" },
      ],
      OpenItems: [],
    };
  },
  watch: {
    DocStatus: {
      handler() {
        this.getOpenItems();
      },
      deep: true,
    },
  },
  methods: {
    defaultDates() {
      if (!this.options.startDate) {
        const today = new Date();
        this.options.startDate = today.toISOString().substr(0, 10);
      }

      if (!this.options.endDate) {
        const today = new Date();
        this.options.endDate = today.toISOString().substr(0, 10);
      }
    },

    editItem(item) {
      console.log(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.editedItem = {};
      this.dialog = false;
    },
    sendData() {
      let rdr1Data = this.selectedItems;
      const filteredData = rdr1Data.map((element) => {
        element.document_lines = element.document_lines.filter(
          (item) => item.OpenQty > 0
        );
        return element;
      });
      let url = "/dispatch/documents";
      let data = {
        // RlpCode: this.Driver,
        // vehicle_id: this.Vehicle,
        ObjType: 212,
        items: filteredData,
      };
      console.log(data);
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          // console.log(res, "r");
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$router.push("/dispatch/dipatch_notes");
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
          self.loader = false;
        });
    },
    reverseDoc(item) {
      let url = `/document/cancellation/${item.ObjType}/${item.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.$refs.snackbar.show(res.ResultDesc, "green");
            this.getOpenItems();
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },
    getOpenItems() {
      const self = this;
      let url = `/dispatch/documents?ObjType=214`;
      if (this.options.startDate) {
        url += `${url.includes("?") ? "&" : "?"}StartDate=${
          this.options.startDate
        }`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}EndDate=${
          this.options.endDate
        }`;
      }

      if (this.options.DocStatus) {
        url += `${url.includes("?") ? "&" : "?"}DocStatus=${
          this.options.DocStatus
        }`;
      }
      if (this.options.search) {
        url += `${url.includes("?") ? "&" : "?"}search=${this.options.search}`;
      }
      if (Object.keys(this.options.drivers).length > 0) {
        url += `${url.includes("?") ? "&" : "?"}RlpCode=${
          this.options.drivers
        }`;
      }
      this.loading = true;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          console.log(res, "open items");
          self.OpenItems = res.ResponseData.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          this.loading = false;
        });
    },
    getDrivers() {
      const self = this;
      this.$store
        .dispatch("get", `/drivers`)
        .then((res) => {
          self.drivers = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getVehicles() {
      const self = this;
      this.$store
        .dispatch("get", `/vehicles`)
        .then((res) => {
          self.vehicles = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDrivers();
    this.getOpenItems();
    this.defaultDates();
    this.getVehicles();
  },
};
</script>

<style lang="scss" scoped>
</style>